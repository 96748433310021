import React, { Component } from 'react';
import Markdown from './Markdown';
import './Entity.css';

class SingleTextArea extends Component {
  constructor(props) {
    super(props);

    this.scope = props.scope;
    this.class = 'entity-' + props.scope;
    this.onUpdate = props.callback;
    this.lines = props.lines ? parseInt(props.lines, 10) : 5;
    this.state = {
      text: props.text,
      status: props.status,
      prevProps: props,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDoneClick = this.handleDoneClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return JSON.stringify(prevState.prevProps) === JSON.stringify(nextProps) ?
      null : { text: nextProps.text, status: nextProps.status, prevProps: nextProps };
  }

  handleEditClick(e) {
    e.preventDefault();

    console.log('HandleEditClick');
    this.setState({
      status: 'EDIT_FOCUS'
    });
  }

  handleDoneClick(e) {
    e.preventDefault();

    console.log('HandleDoneClick');
    this.setState({
      status: 'EDIT_PENDING'
    }, () => this.onUpdate({
      scope: this.scope,
      description: 'Updated ' + this.scope + ' text',
      data: this.state.text ? this.state.text : ''
    }));
  }

  handleCancelClick(e) {
    e.preventDefault();

    console.log('HandleCancelClick');
    this.setState({
      status: 'EDIT'
    });
  }

  handleChange(event) {
    this.setState({
      text: event.target.value
    });
  }

  render() {
    switch(this.state.status) {
      case 'EDIT':
      return (
        <div className={this.class}>
        {this.state.text ? <Markdown text={this.state.text} /> : 'No ' + this.scope + '.'}
        <button className="buttonnext" onClick={this.handleEditClick}>&#x270F;</button>
        </div>
      )
      case 'EDIT_FOCUS':
      return (
        <div className={this.class}>
        <textarea rows={this.lines} name="text" value={this.state.text} onChange={this.handleChange} />
        <button onClick={this.handleCancelClick}>cancel</button>
        <button className="buttonnext" onClick={this.handleDoneClick}>save</button>
        </div>
      )
      case 'EDIT_PENDING':
      return (
        <div className={this.class}>
        {this.state.text ? <Markdown text={this.state.text} /> : 'No ' + this.scope + '.'}
        <button disabled>cancel</button>
        <button className="buttonnext" disabled>save</button>
        </div>
      )
      default: // DISPLAY
      return (
        <div className={this.class}>
        {this.state.text ? <Markdown text={this.state.text} /> : 'No ' + this.scope + '.'}
        </div>
      )
    }
  }
}

export default SingleTextArea;
