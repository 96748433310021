import React, { Component } from 'react';
import Spinner from './Spinner'
import RegistrationService from './services/RegistrationService';
import './UserRegistration.css';

const defaultType = 'friend';

// props = {
//   user: "the user details"
// }
class UserRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      name: props.user ? props.user.name : '',
      type: defaultType,
      status: props.user ? 'KNOWN' : 'DEFAULT'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSuccss = this.handleSuccess.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    RegistrationService.register(this.handleError.bind(this), this.handleSuccess.bind(this), this.state);
    this.setState({
      status: 'PENDING'
    });
  }

  handleError(error) {
    console.error("[UserRegistration] Showed error to user");
    this.setState({
      status: 'ERROR',
      message: error
    });
  }

  handleSuccess(data) {
    console.log("[UserRegistration] Showed thank you message to user");
    console.log("[UserRegistration] data", data);
    this.setState({
      status: 'DONE',
      name: data.item.Name
    });
  }

  render() {
    switch(this.state.status) {
      case 'ERROR':
      return (
        <div className="cont">
          <p className="registration-error">Error: {this.state.message}</p>
          <p>If this looks like a mistake or a transient issue, please <a href="/">try again</a>.</p>
        </div>
        )
      case 'DONE':
        return (
        <div className="cont">
          <p>Thanks for registering with us <b>{this.state.name}</b>!</p>
          <p>
            We'll keep track of you in our records. While you wait to hear back from us, why not attend a few  performing arts events?
            A good place to find some is <em>The Stranger</em>'s <a href="https://www.thestranger.com/events/">event page</a>.
          </p>
        </div>
        )
      case 'PENDING':
      return (
        <div className="cont">
          <Spinner />
        </div>
        )
      case 'KNOWN':
      return (
        <div className="cont">
          <p>Welcome back {this.state.name}.</p>
        </div>
        )
      default:
      return (
        <div className="cont">
          <p className="registration-text">
          If you're interested in becoming a beta user tell us how heard about
          Peforming.World and leave us your contact details. We will reach out to you as
          we continue to send invites to those who want to join our Performing.World.
          </p>
          <p>***</p>
          <form className="registration-form" onSubmit={this.handleSubmit}>
            <label>
              Name:
              <input name="name" type="text" value={this.state.name} onChange={this.handleChange} />
            </label>
            <label>
              Email:
              <input name="email" type="text" value={this.state.email} onChange={this.handleChange} />
              </label>
            <label>
              How did you hear about us?
              <select name="type" value={this.state.type} onChange={this.handleChange}>
                <option value={defaultType}>Friend</option>
                <option value="social-media">Social media</option>
                <option value="search-engine">Search engine</option>
                <option value="paper">Flyer or poster</option>
                <option value="other">Other</option>
              </select>
            </label>
            <div className="registration-button">
              <button onClick={this.handleSubmit}>Submit</button>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default UserRegistration;
