import React, { Component } from 'react';
import AnalyticsService from './services/AnalyticsService';
import RawJson from './RawJson';
import './EntityAnalytics.css';

class EntityAnalytics extends Component {
  constructor(props) {
    super(props);

    const displayAnalytics = props.user && props.user['cognito:username'] === 'araleius';
    this.state = {
      status: displayAnalytics ? 'PENDING' : 'DISABLED',
      showData: false
    };

    this.handleError = this.handleError.bind(this);
    this.handleSuccss = this.handleSuccess.bind(this);
    this.handleShowDataClick = this.handleShowDataClick.bind(this);

    if (displayAnalytics) {
        AnalyticsService.get(this.handleError.bind(this), this.handleSuccess.bind(this), { id: props.id });
    }
  }

  handleShowDataClick(e) {
    e.preventDefault();

    this.setState((prevState, props) => {
      return { showData: !prevState.showData };
    });
  }

  handleError(data) {
    console.error('HandleError', data);
    this.setState({
      message: data,
      status: 'ERROR'
    });
  }

  handleSuccess(data) {
    console.log('HandleSuccess', data);
    this.setState({
      analytics: data.analytics,
      status: 'DONE'
    });
  }

  render() {
    switch(this.state.status) {
      case 'PENDING':
        return (<div>...</div>)
      case 'ERROR':
        return (<div>ERROR: {this.state.message}</div>)
      case 'DONE':
        return (
        <div className="entity-analytics">
          <RawJson hidden={!this.state.showData} data={this.state.analytics} />
          <button onClick={this.handleShowDataClick}>{this.state.showData ? 'hide' : 'show' } analytics</button>
        </div>
        )
      default: // DISABLED
        return (<div className="noanalytics"></div>)
    }
  }
}

export default EntityAnalytics;
