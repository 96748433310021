import React, { Component } from 'react';
import User from './User';
import './Nav.css';


function UserKnown(props) {
  return (
    <nav className="navi">
      <a href="/">home</a>
      &nbsp;|&nbsp;
      <a href="/new">new</a>
      &nbsp;|&nbsp;
      <User user={props.user}/>
    </nav>
  );
}

function UserUnknown() {
  return (
    <nav className="navi">
      Already a beta user? <User user={false}/>
    </nav>
  );
}

function UserUnknownAndHome() {
  return (
    <nav className="navi">
      <a href="/">home</a>
      &nbsp;|&nbsp;
      Already a beta user? <User user={false}/>
    </nav>
  );
}

// props = {
//   user: "the user details"
// }
class Nav extends Component {

  render() {
    const showHome = window.location.pathname.length > 1;
    return this.props.user ?
      <UserKnown user={this.props.user} /> : showHome ? <UserUnknownAndHome /> : <UserUnknown />;
  }
}

export default Nav;
