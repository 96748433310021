import React, { Component } from 'react';
import EntityService from './services/EntityService';
import RawJson from './RawJson';
import EntityAnalytics from './EntityAnalytics';
import SingleTextArea from './SingleTextArea';

function ProductionBox(props) {
  const listItems = (productionRefs) => {
    // incoming relations can be from different scopes, merge them
    const deDuped = {};
    productionRefs.forEach((ref) => {
      if (deDuped.hasOwnProperty(ref.id)) {
        deDuped[ref.id].scope.add(ref.scope);
      } else {
        ref.scope = new Set([ref.scope]);
        deDuped[ref.id] = ref;
      }
    });

    return Object.values(deDuped)
    .sort((a,b) => { // sort by 'from' date
      return a.from < b.from ? 1 : a.from > b.from? -1 : 0;
    })
    .map((ref, index) => {
      if (ref.hasOwnProperty('org') && ref.org.id !== props.entityId) {
        return (
          <div key={index} className="entity-credit entity-credit-display">
            <a href={'/e/' + ref.id.toUpperCase()}>{ref.name}</a>
            <span>...</span>
            <a href={'/e/' + ref.org.id.toUpperCase()}>{ref.org.name}</a>
          </div>
        )
      } else {
        return (
          <div key={index} className="entity-credit entity-credit-name">
            <a href={'/e/' + ref.id.toUpperCase()}>{ref.name}</a>
          </div>
        )
      }
    });
  };

  return (
    <div className="entity-credit-box">
      <h4 className="entity-credit-box-title">{props.year}</h4>
      <div className="entity-credit-box-credits">{listItems(props.productions)}</div>
    </div>
  )
}

class Productions extends Component {
  constructor(props) {
    super(props);

    const productionMap = props.productions || {};

    this.entityId = props.entityId;
    this.productions =
      Object.getOwnPropertyNames(productionMap)
        .sort().reverse()
        .map((y) => {
          return { year: y, productions: Object.values(productionMap[y]) }
        })
        .filter((i) => i.productions && i.productions.length > 0);
  }

  render() {
    if (this.productions.length === 0) return '';
    return this.productions.map((item) => {
      return (
        <ProductionBox key={item.year} entityId={this.entityId} year={item.year} productions={item.productions} />
      )
    });
  }
}

class HeaderWidget extends Component {
  constructor(props) {
    super(props);

    this.entity = props.entity;
  }


  render() {
    return (
    <div className="non-production-header">
    <h1 className="non-production-name">{this.entity.name}</h1>
    <h1 className="non-production-type"><TypeWidget type={this.entity.type} /></h1>
    </div>
  )
  }
}

function TypeWidget(props) {
  switch(props.type) {
    case 'person': return (<span role="img" aria-label={props.type}>&#x2B50;</span>);
    case 'user': return (<span role="img" aria-label={props.type}>&#x1F929;</span>);
    case 'org': return (<span role="img" aria-label={props.type}>&#x1F3DB;</span>);
    default:
    return ''
  }
}

// alt. https://lorempixel.com/235/235/
function PosterImage(props) {
  return (
    <div className="production-image">
      <img src="https://picsum.photos/235/235/?random" alt="poster"/>
    </div>
  )
}

// props = {
//   entity: "the entity data"
// }
class NonProductionEntity extends Component {
  constructor(props) {
    super(props);

    this.user = props.user;
    this.state = {
      entity: props.entity,
      status: 'DISPLAY'
    };

    this.handleStatusUpdate = this.handleStatusUpdate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUpdateSuccess = this.handleUpdateSuccess.bind(this);
    this.handleUpdateError = this.handleUpdateError.bind(this);
  }

  handleStatusUpdate(e) {
    console.log('handleStatusUpdate');
    if (this.state.status === 'EDIT') {
      this.setState({
        status: 'DISPLAY',
      });
    } else { // DISPLAY
      this.setState({
        status: 'EDIT',
        editorPrompt: 'Exit edit mode after making any required changes.'
      });
    }
  }

  handleUpdateError(update, response) {
    console.error('HandleUpdateError', update.description, response);
    this.setState({ editorPrompt: '[FAILED] ' + update.description + ': ' + response});
  }

  handleUpdateSuccess(update, response) {
    console.log('HandleUpdateSuccess', update.description, response);
    this.setState({ entity: response.entity, editorPrompt: update.description });
  }

  /**
   * Will issue a service call to update the entity in the repository.
   *
   * The undelying subcomponent is responsible for sending only
   * real updates: any updates that result in no change should
   * never get as far as this handler.
   */
  handleUpdate(update) {
    console.log('HandleUpdate', update);
    const updateData = Object.assign(update);
    updateData.user = this.user['cognito:username'];
    updateData.id = this.state.entity.id;
    updateData.version = this.state.entity.version;
    updateData.name = this.state.entity.name;
    updateData.from = 'Bananas'; // this is required for production updates, not for people

    EntityService.update(
      (response) => this.handleUpdateError(update, response),
      (response) => this.handleUpdateSuccess(update, response),
      updateData);
  }

  render() {
    const entity = this.state.entity;
    return (
      <div className="page-cont">
      {this.user && <EditorPane username={this.user['cognito:username']}
                                status={this.state.status}
                                entity={this.state.entity}
                                prompt={this.state.editorPrompt}
                                updateCallback={this.handleUpdate}
                                statusCallback={this.handleStatusUpdate}/>}
      <section className="lead entity-lead">
        <HeaderWidget entity={this.state.entity} />
        <div className="production-img-summary">
          <PosterImage />
          <div className="production-summary-box">
            <SingleTextArea status={this.state.status}
                            scope="summary"
                            text={entity.data.overview ? entity.data.overview.summary : 'No summary.'}
                            lines="9"
                            callback={this.handleUpdate} />
          </div>
        </div>
      </section>
      <main className="cont">
        <Productions entityId={this.state.entity.id} productions={this.state.entity.data.productions} />
        <EntityAnalytics user={this.user} id={this.state.entity.id} />
      </main>

      </div>
    )
  }
}

class EditorPane extends Component {
  constructor(props) {
    super(props);

    this.username = props.username;
    this.onUpdate = props.updateCallback;
    this.onStatusChange = props.statusCallback;

    this.state = {
      status: props.status,
      entity: props.entity,
      prompt: props.prompt,
      showRaw: false,
    };

    this.handleShowRawClick = this.handleShowRawClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      status: nextProps.status,
      entity: nextProps.entity,
      prompt: nextProps.prompt
    };
  }

  handleShowRawClick(e) {
    e.preventDefault();

    this.setState((prevState, props) => {
      return { showRaw: !prevState.showRaw };
    });
  }

  handleEditClick(e) {
    e.preventDefault();

    console.log('HandleEditClick');
    this.setState({
      status: 'EDIT_FOCUS'
    });
  }

  handleDoneClick(e) {
    e.preventDefault();

    console.log('HandleDoneClick');
    this.setState({
      status: 'EDIT_PENDING'
    }, () => this.onUpdate({
      ref: { id: this.state.id, name: this.state.name },
      credit: this.state.credit
    }));
  }

  render() {
    return (
      <div className="edit entity-editor">
        <div className="entity-editor-title">
          <h4>Editor ({this.username})</h4>
          <div className="entity-editor-toggle">
            <button onClick={this.onStatusChange}>{this.state.status === 'DISPLAY' ? 'enter' : 'exit'} edit mode</button>
          </div>
        </div>
        {this.state.status !== 'DISPLAY' &&
          <div className="entity-editor-content">
            <h5>Notes:</h5>
            <SingleTextArea status={this.state.status} scope="notes" text={this.state.entity.metadata.notes} callback={this.onUpdate} />
            {this.state.prompt && <h5>Messages:</h5>}
            <p>{this.state.prompt}</p>
            <RawJson hidden={!this.state.showRaw} data={this.state.entity} />
            <button onClick={this.handleShowRawClick}>{this.state.showRaw ? 'hide' : 'show' } raw data</button>
          </div>
        }
      </div>
    );
  }
}

export default NonProductionEntity;