
import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth';
import Constants from '../config/Constants';

var authData = {
  UserPoolId : Constants.cognito.UserPoolId,
  ClientId : Constants.cognito.ClientId,
  AppWebDomain : Constants.cognito.AppWebDomain,
  TokenScopesArray : ['openid'],
  RedirectUriSignIn : 'https://' + Constants.appDomain,
  RedirectUriSignOut : 'https://' + Constants.appDomain
};
var auth = new CognitoAuth(authData);
auth.userhandler = {
  onSuccess: function(session) {
    console.log('[AuthService] Cognito.auth.onSuccess session:', session);
    // TODO: maybe notify some register listeners which
    // would react to the auth success (e.g. the User.js
    // component would render the user name and sign out

    // Redirect to root, identity will be retrieved from
    // the cached session on reload.
    window.location = '/';
  },
  onFailure: function(err) {
    console.error('[AuthService] Cognito.auth.onFailure error', err);
  }
};
auth.useCodeGrantFlow();
if (window.location.search) {
  auth.parseCognitoWebResponse(window.location.href);
}

const getTokenData = (session) => {
  if (session && session.isValid()) {
    var idToken = session.getIdToken().getJwtToken();
    if (idToken) {
      var payload = idToken.split('.')[1];
      var tokenData = JSON.parse(atob(payload));
      console.log('[AuthService] successfully retrieved identity from session');
      return tokenData;
    }
  } else {
    console.log('[AuthService] failed to retrieve identity from session');
  }
};

const AuthService = {
  signIn: () => auth.getSession(),
  signOut: () => auth.signOut(),
  getIdentity: function() {
    var session = auth.getCachedSession();
    return getTokenData(session);
  }
}

export default AuthService;