const region = 'us-west-2';
const betaUserPoolId = 'us-west-2_PCXfif5tp';
const betaUserPoolClientId = '4km5d6t8tj853s9kvguldjec9j';
const prodUserPoolId = 'us-west-2_OcG5KMZWe';
const prodUserPoolClientId = '4darq0pe3bm4hdsjag67vbjiuq';

const beta = {
  stage: "beta",
  appName: "Beta.Performing.World",
  appDomain: window.location.host,
  cognito: {
    UserPoolId: betaUserPoolId,
    ClientId: betaUserPoolClientId,
    AppWebDomain: "beta-performing-world.auth.us-west-2.amazoncognito.com"
  },
  amplify: {
    Auth: {
      region: region,
      identityPoolId: "us-west-2:d95a0f4d-8988-4b3b-83a0-22fc79952e29",
      userPoolId: betaUserPoolId,
      userPoolWebClientId: betaUserPoolClientId
    },
    API: {
      region: region,
      endpoints: [
        {
          name: "DataService",
          endpoint: "https://v9j5jhaxyl.execute-api.us-west-2.amazonaws.com/Prod"
        }
      ]
    }
  },

}

const prod = {
  stage: "prod",
  appName: "Performing.World",
  appDomain: "performing.world",
  cognito: {
    UserPoolId: prodUserPoolId,
    ClientId: prodUserPoolClientId,
    AppWebDomain: "performing-world.auth.us-west-2.amazoncognito.com"
  },
  amplify: {
    Auth: {
      region: region,
      identityPoolId: "us-west-2:0ab0116c-20bf-4026-ad81-ad0614cf36df",
      userPoolId: prodUserPoolId,
      userPoolWebClientId: prodUserPoolClientId
    },
    API: {
      region: region,
      endpoints: [
        {
          name: "DataService",
          endpoint: "https://w2sd9k269b.execute-api.us-west-2.amazonaws.com/Prod"
        }
      ]
    }
  }
}

const where = window.location.host;
const config = where === prod.appDomain ? prod : beta;

export default config;
