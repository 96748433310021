import React from 'react';
import ReactDOM from 'react-dom';
import UniversalRouter from 'universal-router'
import './assets/vendor/normalize.css';
import './index.css';
import AuthService from './services/AuthService'
import Home from './Home';
import New from './New';
import Entity from './Entity';
import NotFound from './NotFound';

const identity = AuthService.getIdentity() || false;

const routes = [
  { path: '', action: () => <Home user={identity}/> },
  { path: '/new',
    children: [
      { action: () => <New user={identity} /> }
  ]},
  { path: '/e',
    children: [
      { path: '/([a-z0-9-]+)', action: (context) => <Entity id={context.path.substring(1)} user={identity}/> }
  ]},
  { path: '(.*)', action: () => <NotFound user={identity}/> }
]

const router = new UniversalRouter(routes);
router.resolve(window.location.pathname).then(component => {
  ReactDOM.render(component, document.getElementById('root'))
});
