import React, { Component } from 'react';
import Nav from './Nav';
import Spinner from './Spinner'
import EntityService from './services/EntityService';
import './Page.css';
import './New.css';

const defaultType = 'theater';
const defaultState = 'WA';

class ProductionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: defaultType,
      state: defaultState,
      name: '',
      org: '',
      city: '',
      from: '',
      to: ''
    };

    this.onFormUpdate = props.onFormUpdate;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    // TODO: Additional validation (probably in specific components)
    this.onFormUpdate(this.state);
  }

  render() {
    return (
    <main className="cont">
      <form className="registration-form" onSubmit={this.handleSubmit}>
      <label className="registration-category">
          <span>Category:</span>
          <select name="category" value={this.state.category} onChange={this.handleChange}>
            <option value={defaultType}>Theater</option>
            <option value="music">Music</option>
            <option value="danse">Danse</option>
          </select>
        </label>
        <label>
          Title:
          <input name="name" type="text" value={this.state.name} onChange={this.handleChange} />
        </label>
        <label>
          Produced By:
          <input name="org" type="text" value={this.state.org} onChange={this.handleChange} />
        </label>
        <div className="registration-location">
          <label>
            City:
            <input name="city" type="text" value={this.state.city} onChange={this.handleChange} />
          </label>
          <label>
            State:
            <select name="state" value={this.state.state} onChange={this.handleChange}>
            <option value={defaultState}>WA</option>
            <option value="OR">OR</option>
          </select>
          </label>
        </div>
        <div className="registration-dates">
          <label>
            From:<br/>
            <input name="from" type="date" placeholder="2018-01-01" value={this.state.from} onChange={this.handleChange} />
          </label>
          <label>
            To:<br/>
            <input name="to" type="date" placeholder="2018-01-31" value={this.state.to} onChange={this.handleChange} />
          </label>
        </div>
        <div className="registration-button">
          <button onClick={this.handleSubmit}>Add to Performing.World</button>
        </div>
      </form>
    </main>
    );
  }
}

// props = {
//   user: "the user details"
// }
class NewCont extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: props.user['cognito:username'],
      status: props.user && props.user['cognito:username'] ? 'DISPLAY' : 'NO_USER'
    };

    this.handleFormUpdate = this.handleFormUpdate.bind(this);
  }

  handleError(data) {
    console.error('HandleError', data);
    this.setState({
      message: JSON.stringify(data),
      status: 'ERROR'
    });
  }

  handleSuccess(data) {
    console.log('HandleSuccess', data);
    this.setState({
      id: data.production.id,
      status: 'DONE'
    });
  }

  /** This is where the call to create a NEW entity in the repository should be issued. */
  handleFormUpdate(data) {
    this.setState({
      formData: data,
      name: data.name,
      status: 'PENDING'
    });

    EntityService.newProduction(this.handleError.bind(this), this.handleSuccess.bind(this), {
      user: this.state.user,
      name: data.name.trim(),
      org: data.org.trim(),
      category: data.category,
      to: data.to,
      from: data.from,
      city: data.city.trim(),
      state: data.state,
    });
  }

  render() {
    switch(this.state.status) {
      case 'NO_USER':
      return (
        <div className="cont">
          <p>Please sign in first.</p>
        </div>
        )
      case 'ERROR':
      return (
        <div className="cont">
          <p className="new-production-error">Error: {this.state.message}</p>
          <p>If this looks like a mistake or a transient issue, please <a href="/new">try again</a>.</p>
        </div>
        )
      case 'DONE':
        return (
        <div className="cont">
          <p>Thanks for adding <b>{this.state.name}</b> to Performing.World!</p>
          <p>
            Add more details <a href={'/e/' + this.state.id.toUpperCase()}>here</a>.
          </p>
        </div>
        )
      case 'PENDING':
      return (
        <div className="cont">
          <Spinner />
        </div>
        )
      default: // DISPLAY
      return (
        <ProductionForm onFormUpdate={this.handleFormUpdate}/>
      );
    }
  }
}

// props = {
//   user: "the user details"
// }
class New extends Component {
  render() {
    return (
      <div className="page">
        <Nav user={this.props.user}/>
        <header className="spot">
          <figure className="spot-figure spot-emoji">
            <span role="img" aria-label="sunrise">&#x1F305;</span>
          </figure>
        </header>
        <section className="lead">
          <p>
          Add a production to Performing.World
          </p>
        </section>
        <NewCont user={this.props.user}/>
      </div>
    );
  }
}

export default New;
