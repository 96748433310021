import React, { Component } from 'react';
import brice from './assets/svg/brice-and-text.svg';
import './Page.css';
import Nav from './Nav';
import UserRegistration from './UserRegistration';

// props = {
//   user: "the user details"
// }
class Home extends Component {
  render() {
    return (
      <div className="page">
        <Nav user={this.props.user}/>
        <header className="spot">
          <figure className="spot-figure">
            <img src={brice} className="spot-image" alt="Performing.World" />
          </figure>
        </header>
        <section className="lead">
          <p>
            Performing.World is the digital footprint of the performing arts in Seattle and the Puget Sound region.
          </p>
        </section>
        <main className="cont">
          <UserRegistration user={this.props.user}/>
        </main>
      </div>
    );
  }
}

export default Home;
