import React from 'react';

function RawJson(props) {
  return (
    <div  className={props.hidden ? 'hidden' : undefined}>
      <h5>Raw Data:</h5>
      <pre>{JSON.stringify(props.data, null, 2)}</pre>
    </div>
  );
}

export default RawJson;
