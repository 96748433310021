import React, { Component } from 'react';
import marked from 'marked';

import './Markdown.css';

class Markdown extends Component {
  constructor(props) {
    super(props);

    this.state = { text: props.text };
  }

  render() {
    const html = marked(this.state.text || '');

    return (<div>
      <div className="markdown" dangerouslySetInnerHTML={{__html: html}} />
    </div>);
  }
}

export default Markdown;
