import Amplify, { API } from 'aws-amplify';
import Constants from '../config/Constants';

Amplify.configure(Constants.amplify);

const newProduction = (errorCb, successCb, data) => {
    console.log("Create Production", data);

    API.post('DataService', '/data/v1/entities', {
        body: data
    })
    .then(response => {
      successCb(response);
    })
    .catch((err) => {
      errorCb(toErrorMessageForDisplay(err));
    });
};

const get = (errorCb, successCb, data) => {
    console.log("Get Entity", data);

    API.get('DataService', '/data/v1/entities/' + data.id)
    .then(response => {
      successCb(response);
    })
    .catch((err) => {
      errorCb(toErrorMessageForDisplay(err));
    });
};

const toErrorMessageForDisplay = (err) => {
  var message = 'Something went wrong, please try again later.';
  if (err.response) {
    const isJson = err.response.headers['content-type'].startsWith('application/json');
    switch (err.response.status) {
      case 500:
        message = message + ' ' + (isJson ? JSON.stringify(err.response.data) : err.response.data );
        break;
      default: // 400, 404
        message = (isJson ? JSON.stringify(err.response.data) : err.response.data );
        break;
    }
  }
  return message;
};

const update = (errorCb, successCb, data) => {
    console.log("Update Entity", data);

    API.post('DataService', '/data/v1/entities/update', {
        body: data
    })
    .then(response => {
      successCb(response);
    })
    .catch((err) => {
      errorCb(toErrorMessageForDisplay(err));
    });
};

const del = (errorCb, successCb, data) => {
  console.log("Delete Entity", data);

  API.del('DataService',
  '/data/v1/entities/'
    + data.id
    + '/' + data.version
    + '?user=' + data.user
    + '&name=' + data.name
  )
  .then(response => {
    successCb(response);
  })
  .catch((err) => {
    errorCb(toErrorMessageForDisplay(err));
  });
};

export default {
    newProduction: newProduction,
    get: get,
    update: update,
    delete: del
}