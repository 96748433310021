import Amplify, { API } from 'aws-amplify';
import Constants from '../config/Constants';

Amplify.configure(Constants.amplify);

const register = (errorCb, successCb, data) => {
    console.log("Register", data);

    API.post('DataService', '/data/v1/registrations', {
        body: { name: data.name.trim(), email: data.email.trim(), types: [data.type]  }
    })
    .then(response => {
        successCb(response);
    })
    .catch((err) => {
        var message = 'Something went wrong, please try again later.';
        if (err.response) {
            switch (err.response.status) {
                case 400:
                    message = 'Bad data, make sure you provide all the information requested.';
                    break;
                default:
                    break;
            }
        }
        errorCb(message);
    });
};

export default {
    register: register
}