import React, { Component } from 'react';
import AuthService from './services/AuthService'
import './User.css';


function UserKnown(props) {
  return (<div className="user">
    <div className="user user-known">
      <span className="user-name">
        <a href={'/e/' + props.user['cognito:username']}>{props.user.name}</a>
      </span>
      &nbsp;
      <button onClick={AuthService.signOut}>sign out</button>
    </div>
  </div>
  );
}

function UserUnknown(props) {
  return (<div className="user">
    <div className="user user-unknown">
      <button onClick={AuthService.signIn}>sign in</button>
    </div>
  </div>
  );
}

class User extends Component {
  render() {
    return this.props.user ? <UserKnown user={this.props.user} /> : <UserUnknown />;
  }
}

export default User;
