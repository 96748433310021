import React, { Component } from 'react';
import RawJson from './RawJson';
import SingleTextArea from './SingleTextArea';
import EntityAnalytics from './EntityAnalytics';
import EntityService from './services/EntityService';

/**
 * props:
 * {
 *   status: to figure out if this should even be displayed
 *   callback: onClickHandler
 * }
 */
class CreditAdder extends Component {
  constructor(props) {
    super(props);

    this.text = props.text;
    this.onClickHandler = props.callback;
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  handleAddClick(e) {
    e.preventDefault();

    console.log('handleAddClick');
    this.onClickHandler();
  }

  render() {
    return (
      <div className="entity-credit-box-adder">
      <button onClick={this.handleAddClick}>+ add {this.text} credit</button>
      </div>
    )
  }

}

class SingleCredit extends Component {
  constructor(props) {
    super(props);

    this.scope = props.scope;
    this.onUpdate = props.updateCallback;
    this.onDelete = props.deleteCallback;
    this.onFocus = props.focusCallback;

    const hasFocus = props.focus === props.id;

    this.state = {
      status: hasFocus ? 'EDIT_FOCUS' : props.status || 'DISPLAY',
      focus: props.focus,
      name: props.name,
      credit: props.credit,
      id: props.id,
      prevProps: props
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDoneClick = this.handleDoneClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);

    this.handleChange = this.handleChange.bind(this);

    if (hasFocus) {
      this.onFocus(this.state.id);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const hasFocus = nextProps.focus === nextProps.id,
      isDisabled = !hasFocus && nextProps.focus; // some sibling has focus

    return JSON.stringify(prevState.prevProps) === JSON.stringify(nextProps) ?
      null : {
        status: hasFocus ? 'EDIT_FOCUS' : (isDisabled ? 'EDIT_DISABLED' : nextProps.status || 'DISPLAY'),
        focus: nextProps.focus,
        name: nextProps.name,
        credit: nextProps.credit,
        id: nextProps.id,
        prevProps: nextProps
      };
  }

  handleEditClick(e) {
    e.preventDefault();

    console.log('HandleEditClick');
    this.setState({
      status: 'EDIT_FOCUS'
    }, () => this.onFocus(this.state.id) );
  }

  handleDoneClick(e) {
    e.preventDefault();

    console.log('HandleDoneClick');
    this.setState({
      status: 'EDIT_PENDING'
    }, () => this.onUpdate({
      ref: { id: this.state.id, name: this.state.name.trim() },
      credit: this.state.credit.map(c => c.trim())
    }));
  }

  handleDeleteClick(e) {
    e.preventDefault();

    console.log('HandleDeleteClick');
    this.setState({
      status: 'EDIT_PENDING'
    }, () => this.onDelete({
      ref: { id: this.state.id, name: this.state.name },
      credit: this.state.credit.map(c => c.trim())
    }));
  }

  handleCancelClick(e) {
    e.preventDefault();
    console.log('HandleCancelClick');
    if (this.state.id.startsWith('NEW')) {
      this.handleDeleteClick(e);
    } else {
      this.setState({
        status: 'EDIT'
      });
      this.onFocus(null);
    }
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name,
          value = target.name === 'name' ? target.value : target.value.split(', ');

    this.setState({
      [name]: value
    });
  }

  renderCredit(credit) {
    return Array.isArray(credit) ? credit.join(', ') : credit;
  }

  render() {
    switch(this.state.status) {
      case 'EDIT':
      return (
        <div className="entity-credit entity-credit-edit">
        <button onClick={this.handleEditClick}>&#x270F;</button>
        <span className="buttonnext">{this.state.name}</span>
        <span>...</span>
        <span>{this.renderCredit(this.state.credit)}</span>
        </div>
      )
      case 'EDIT_DISABLED':
      return (
        <div className="entity-credit entity-credit-edit">
        <button disabled>&#x270F;</button>
        <span className="buttonnext">{this.state.name}</span>
        <span>...</span>
        <span>{this.renderCredit(this.state.credit)}</span>
        </div>
      )
      case 'EDIT_FOCUS':
      return (
        <div className="entity-credit entity-credit-focus">
        <form className="entity-credit-focus-form">
          <div>
          Name: <input name="name" type="text" value={this.state.name} onChange={this.handleChange} />
          </div>
          <div>
          Credit(s): <input name="credit" type="text" value={this.renderCredit(this.state.credit)} onChange={this.handleChange} />
          </div>
        </form>
        <div className="entity-credit-focus-buttons">
          <button onClick={this.handleCancelClick}>cancel</button>
          <button className="buttonnext" onClick={this.handleDeleteClick}>delete</button>
          <button className="buttonnext" onClick={this.handleDoneClick}>save</button>
        </div>
        </div>
      )
      case 'EDIT_PENDING':
      return (
        <div className="entity-credit entity-credit-pending">
        <form className="entity-credit-focus-form">
          <div>
          Name: <input name="name" type="text" value={this.state.name} onChange={this.handleChange} />
          </div>
          <div>
          Credit(s): <input name="credit" type="text" value={this.renderCredit(this.state.credit)} onChange={this.handleChange} />
          </div>
        </form>
        <div className="entity-credit-focus-buttons">
          <button disabled>cancel</button>
          <button className="buttonnext" disabled>delete</button>
          <button className="buttonnext" disabled>save</button>
        </div>
        </div>
      )
      default: // DISPLAY
      return (
        <div className="entity-credit entity-credit-display">
          <a href={'/e/' + this.state.id.toUpperCase()}>{this.state.name}</a>
          <span>...</span>
          <span>{this.renderCredit(this.state.credit)}</span>
        </div>
      )
    }

  }
}

class CreditBoxAdder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: props.status,
      currentBoxes: new Set(props.currentBoxes),
      title: '',
      prevProps: props
    };

    this.onSectionAdded = props.callback;
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDoneClick = this.handleDoneClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return JSON.stringify(prevState.prevProps) === JSON.stringify(nextProps) ?
      null : {
        status: nextProps.status,
        currentBoxes: new Set(nextProps.currentBoxes),
        title: '',
        prevProps: nextProps
      };
  }

  handleAddClick(e) {
    e.preventDefault();

    console.log('handleAddBoxClick');
    this.setState({
      status: 'EDIT_DISABLED',
      message: 'Provide a new section title'
    });
  }

  handleChange(e) {
    const value = e.target.value.toLocaleLowerCase();

    if (value.length === 0) {
      console.warn("empty value");
      this.setState({
        title: value,
        status: 'EDIT_DISABLED',
        message: 'Provide new section name'
      });
    } else if (this.state.currentBoxes.has(value)) {
      console.warn("known value:", value);
      this.setState({
        title: value,
        status: 'EDIT_DISABLED',
        message: 'Title already in use'
      });
    } else {
      console.log("unknown value:", value);
      this.setState({
        title: value,
        status: 'EDIT_FOCUS'
      });
    }
  }

  handleDoneClick(e) {
    e.preventDefault();

    console.log('HandleDoneClick');
    this.setState({
      status: 'EDIT_PENDING'
    }, () => this.onSectionAdded(this.state.title));
  }

  handleCancelClick(e) {
    e.preventDefault();

    console.log('HandleCancelClick');
    this.setState({
      title: '',
      status: 'EDIT'
    });
  }

  render() {
    switch(this.state.status) {
      case 'EDIT':
      return (
        <div className="entity-credit-box">
        <h4 className="entity-credit-box-title"><button onClick={this.handleAddClick}>+ add new credit section</button></h4>
        </div>
      )
      case 'EDIT_FOCUS':
      return (
        <div className="entity-credit-box">
        <h4 className="entity-credit-box-title">
          <input type="text" value={this.state.title} onChange={this.handleChange} />
          <button className="buttonnext" onClick={this.handleCancelClick}>cancel</button>
          <button className="buttonnext" onClick={this.handleDoneClick}>add</button>
        </h4>
        </div>
      )
      case 'EDIT_DISABLED':
      return (
        <div className="entity-credit-box">
        <h4 className="entity-credit-box-title">
          <input type="text" value={this.state.title} onChange={this.handleChange} />
          <button className="buttonnext" onClick={this.handleCancelClick}>cancel</button>
          <button className="buttonnext" disabled>add</button>
          <span className="entity-credit-box-title-disabled-message">{this.state.message}</span>
        </h4>
        </div>
      )
      case 'EDIT_PENDING':
      return (
        <div className="entity-credit-box">
        <h4 className="entity-credit-box-title">{this.state.title}</h4>
        </div>
      )
      default: // DISPLAY
      return ''
    }
  }

}

class CreditBox extends Component {
  constructor(props) {
    super(props);

    this.scope = props.scope;
    this.title = props.title;
    this.onUpdate = props.callback;

    this.state = {
      credits: props.credits,
      status: props.status,
      focus: false,
      newCounter: 0,
      prevProps: props
    };

    this.setFocus = this.setFocus.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return JSON.stringify(prevState.prevProps) === JSON.stringify(nextProps) ?
      null : {
        status: nextProps.status,
        credits: nextProps.credits,
        prevProps: nextProps
      };
  }

  setFocus(id) {
    console.log('setFocus:', id);
    this.setState({ focus: id });
  }

  handleUpdate(creditItem) {
    console.log('handleUpdate');
    var action = 'Updated';
    const update = { scope: ['credits', this.scope] };
    const newCredits = this.state.credits.map((c) => {
      if (c.ref.id === creditItem.ref.id) {
        if (creditItem.ref.id.startsWith('NEW')) {
          action = 'Added';
          delete creditItem.ref.id;
        } else if (c.ref.name !== creditItem.ref.name) {
          action = 'Replaced';
          update.detach = creditItem.ref.id; // there can be only one!
          delete creditItem.ref.id;
        }
        return creditItem;
      }
      return c;
    });

    var description = action + ' ' + this.title + ' credit for: ' + creditItem.ref.name;
    this.onUpdate(Object.assign({
      description: description,
      data: newCredits
    }, update));

    this.setFocus(false);
  }

  handleDelete(creditItem) {
    console.log('handleDelete');
    const newCredits = this.state.credits.filter(c => c.ref.id !== creditItem.ref.id);
    if (creditItem.ref.id.startsWith('NEW')) { // no service call needed
      this.setState({
        credits: newCredits
      });
    } else {
      this.onUpdate({
        scope: ['credits', this.scope],
        description: 'Deleted ' + this.state.title + ' credit for: ' + creditItem.ref.name,
        detach: creditItem.ref.id,
        data: newCredits
      });
    }

    this.setFocus(false);
  }

  handleAdd() {
    console.log('handleAdd');
    this.setState((prevState, props) => {
      const newCredits = JSON.parse(JSON.stringify(prevState.credits)),
        newId = 'NEW' + (prevState.newCounter + 1);

      newCredits.push({ credit: [], ref: { id: newId, name: ''}});
      return { credits: newCredits, newCounter: prevState.newCounter+1, focus: newId };
    });
  }

  listItems(credits) {
    if (!credits) return '';
    return credits.map((item) => {
      return (
           <SingleCredit key={item.ref.id}
                         status={this.state.status}
                         focus={this.state.focus}
                         id={item.ref.id} name={item.ref.name} credit={item.credit}
                         focusCallback={this.setFocus}
                         updateCallback={this.handleUpdate}
                         deleteCallback={this.handleDelete} />
      )
    });
  }

  render() {
    if (this.state.status !== 'EDIT' && (!this.state.credits || this.state.credits.length === 0)) return '';
    return (
      <div className="entity-credit-box">
        <h4 className="entity-credit-box-title">{this.title}</h4>
        <div className="entity-credit-box-credits">{this.listItems(this.state.credits)}</div>
        {this.state.status === 'EDIT' && !this.state.focus && <CreditAdder text={this.title} callback={this.handleAdd} />}
      </div>
    );
  }
}

class LocationWidget extends Component {
  constructor(props) {
    super(props);

    this.onUpdate = props.callback;
    this.location = props.location;
    this.humanFrom = this.toHumanDate(props.dates.from, props.dates.from.substr(0,4) === props.dates.to.substr(0,4));
    this.humanTo = this.toHumanDate(props.dates.to);

    this.state = {
      name: props.location.venue.name,
      id: props.location.venue.id,
      status: props.status,
      prevProps: props
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDoneClick = this.handleDoneClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.locationWhen = this.locationWhen.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return JSON.stringify(prevState.prevProps) === JSON.stringify(nextProps) ?
      null : {
        name: nextProps.location.venue.name,
        id: nextProps.location.venue.id,
        status: nextProps.status,
        prevProps: nextProps
      };
  }

  toHumanDate(dateS, skipYear) {
    const date = new Date(Date.parse(dateS)),
      baseS = date.toUTCString(),
      start = baseS.substring(0,3) + ', ' + baseS.substring(8,11) + ' ',
      day = date.getUTCDate(),
      year = skipYear ? '' : ', ' + date.getUTCFullYear()
      ;

    return start + day + year;
  }

  handleEditClick(e) {
    e.preventDefault();

    console.log('HandleEditClick');
    this.setState({
      status: 'EDIT_FOCUS'
    });
  }

  handleDoneClick(e) {
    e.preventDefault();

    console.log('HandleDoneClick');

    if (this.location.venue.name === this.state.name) {
      this.setState({
        status: 'DISPLAY'
      })
    } else {
      this.setState({
        status: 'EDIT_PENDING'
      }, () => this.onUpdate({
        scope: 'venue',
        description: 'Updated venue',
        data: { name: this.state.name }
      }));
    }
  }

  handleCancelClick(e) {
    e.preventDefault();

    console.log('HandleCancelClick');
    this.setState({
      status: 'EDIT'
    });
  }

  handleChange(event) {
    this.setState({
      name: event.target.value
    });
  }

  locationWhen() {
    return (
      <div>
        {this.humanFrom} - {this.humanTo}
      </div>
    )
  }

  locationWhere() {
    return (
      <div>
        {this.location.city}, {this.location.state}
      </div>
    )
  }

  render() {
    switch(this.state.status) {
      case 'EDIT':
      return (
        <div className="entity-location">
        {this.locationWhen()}
        {this.state.name}
        <button className="buttonnext" onClick={this.handleEditClick}>&#x270F;</button>
        {this.locationWhere()}
        </div>
      )
      case 'EDIT_FOCUS':
      return (
        <div className="entity-location">
        {this.locationWhen()}
        <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
        <div className="entity-location-buttons">
          <button className="buttonnext" onClick={this.handleCancelClick}>cancel</button>
          <button className="buttonnext" onClick={this.handleDoneClick}>save</button>
        </div>
        {this.locationWhere()}
        </div>
      )
      case 'EDIT_PENDING':
      return (
        <div className="entity-location">
        {this.locationWhen()}
        {this.state.name}
        <div className="entity-location-buttons">
          <button className="buttonnext" disabled>cancel</button>
          <button className="buttonnext" disabled>save</button>
        </div>
        {this.locationWhere()}
        </div>
      )
      default: // DISPLAY
      return (
        <div className="entity-location">
        {this.locationWhen()}
        <span><a href={'/e/' + this.state.id.toUpperCase()}>{this.state.name}</a></span>
        {this.locationWhere()}
        </div>
      )
    }
  }
}


class GenreWidget extends Component {
  constructor(props) {
    super(props);

    this.onUpdate = props.callback;
    this.state = {
      category: props.category,
      genres: props.genres,
      status: props.status,
      prevProps: props
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDoneClick = this.handleDoneClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getGenres = this.getGenres.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return JSON.stringify(prevState.prevProps) === JSON.stringify(nextProps) ?
      null : { genres: nextProps.genres, status: nextProps.status, prevProps: nextProps };
  }

  handleEditClick(e) {
    e.preventDefault();

    console.log('HandleEditClick');
    this.setState({
      status: 'EDIT_FOCUS'
    });
  }

  handleDoneClick(e) {
    e.preventDefault();

    console.log('HandleDoneClick');
    this.setState({
      status: 'EDIT_PENDING'
    }, () => this.onUpdate({
      scope: 'genres',
      description: 'Updated genres',
      data: this.state.genres.map(g => g.trim())
    }));
  }

  handleCancelClick(e) {
    e.preventDefault();

    console.log('HandleCancelClick');
    this.setState({
      status: 'EDIT'
    });
  }

  handleChange(event) {
    this.setState({
      genres: !event.target.value || event.target.value.length === 0 ? [] : event.target.value.split(', ')
    });
  }

  getGenres() {
    return this.state.genres.join(', ');
  }

  render() {
    switch(this.state.status) {
      case 'EDIT':
      return (
        <h3 className="production-genre">
          <span className="production-category">{this.state.category}</span> | {this.getGenres()}
          <button className="buttonnext" onClick={this.handleEditClick}>&#x270F;</button>
        </h3>
      )
      case 'EDIT_FOCUS':
      return (
        <h3 className="production-genre">
          <span className="production-category">{this.state.category}</span> | <input type="text" name="text" value={this.getGenres()} onChange={this.handleChange} />
          <button className="buttonnext" onClick={this.handleCancelClick}>cancel</button>
          <button className="buttonnext" onClick={this.handleDoneClick}>save</button>
        </h3>
      )
      case 'EDIT_PENDING':
      return (
        <h3 className="production-genre">
          <span className="production-category">{this.state.category}</span>  | {this.getGenres()}
          <button className="buttonnext" disabled>cancel</button>
          <button className="buttonnext" disabled>save</button>
        </h3>
      )
      default: // DISPLAY
      return (
        <h3 className="production-genre">
          <span className="production-category">{this.state.category}</span> {this.getGenres().length > 0 && ' | ' + this.getGenres()}
        </h3>
      )
    }
  }
}

class HeaderWidget extends Component {
  constructor(props) {
    super(props);

    this.onUpdate = props.callback;

    this.state = {
      status: props.status,
      entity: props.entity,
      prevProps: props
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return JSON.stringify(prevState.prevProps) === JSON.stringify(nextProps) ?
      null : { entity: nextProps.entity, status: nextProps.status, prevProps: nextProps };
  }

  render() {
    return (
      <div className="production-header">
      <h2 className="production-producer">
        {this.state.entity.data.overview.producedBy.name}
        <span className="production-year">({this.state.entity.data.overview.dates.from.substr(0,4)})</span>
      </h2>
      <h1 className="production-title">{this.state.entity.name}</h1>
      <GenreWidget status={this.state.status}
                   category={this.state.entity.data.overview.category}
                   genres={this.state.entity.data.overview.genres}
                   callback={this.onUpdate} />
      </div>
    )
  }
}

// alt. https://lorempixel.com/235/235/
function PosterImage(props) {
  return (
    <div className="production-image">
      <img src="https://picsum.photos/235/235/?random" alt="poster"/>
    </div>
  )
}

// props = {
//   entity: "the entity data"
// }
class ProductionEntity extends Component {
  constructor(props) {
    super(props);

    this.user = props.user;
    this.state = {
      entity: props.entity,
      status: 'DISPLAY'
    };

    this.handleStatusUpdate = this.handleStatusUpdate.bind(this);
    this.handleAddCreditSection = this.handleAddCreditSection.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdateSuccess = this.handleUpdateSuccess.bind(this);
    this.handleUpdateError = this.handleUpdateError.bind(this);
    this.listCreditBoxes = this.listCreditBoxes.bind(this);
  }

  handleStatusUpdate(e) {
    console.log('handleStatusUpdate');
    if (this.state.status === 'EDIT') {
      this.setState({
        status: 'DISPLAY',
      });
    } else { // DISPLAY
      this.setState({
        status: 'EDIT',
        editorPrompt: 'Exit edit mode after making any required changes.'
      });
    }
  }

  handleAddCreditSection(e) {
    console.log('handleAddCreditSection');
    console.log("Adding", e);
    const entity = this.state.entity;
    entity.data.credits[e] = [];
    this.setState( { entity: entity } );
  }

  handleUpdateError(update, response) {
    console.error('HandleUpdateError', update.description, response);
    this.setState({ editorPrompt: '[FAILED] ' + update.description + ': ' + response});
  }

  handleUpdateSuccess(update, response) {
    console.log('HandleUpdateSuccess', update.description, response);
    this.setState({ entity: response.entity, editorPrompt: update.description });
  }

  listCreditBoxes(names) {
    if (!names) return ''; // TODO: Figure out a way to put some default
    return names.map((name) => {
      return (
        <CreditBox key={name} scope={name} title={name}
          status={this.state.status}
          credits={this.state.entity.data.credits[name]}
          callback={this.handleUpdate} />
      )
    });
  }

  /**
   * Will issue a service call to update the entity in the repository.
   *
   * The undelying subcomponent is responsible for sending only
   * real updates: any updates that result in no change should
   * never get as far as this handler.
   */
  handleUpdate(update) {
    console.log('HandleUpdate', update);
    const updateData = Object.assign(update);
    updateData.user = this.user['cognito:username'];
    updateData.name = this.state.entity.name;
    updateData.id = this.state.entity.id;
    updateData.version = this.state.entity.version;
    updateData.org = this.state.entity.data.overview.producedBy;
    updateData.from = this.state.entity.data.overview.dates.from;

    EntityService.update(
      (response) => this.handleUpdateError(update, response),
      (response) => this.handleUpdateSuccess(update, response),
      updateData);
  }

  handleDeleteError(response) {
    console.error('HandleDeleteError', response);
    this.setState({ editorPrompt: '[FAILED] Could not delete: ' + response});
  }

  handleDeleteSuccess(response) {
    console.log('HandleDeleteSuccess', response);
    this.setState( { status: 'DELETED' } );
  }

  handleDelete() {
    console.log('HandleDelete');

    if (window.confirm('Select OK if you really want to delete: "' + this.state.entity.name + '". This action cannot be undone.')){
      const deleteData = {
        user: this.user['cognito:username'],
        name: this.state.entity.name,
        id: this.state.entity.id,
        version: this.state.entity.version
      };
      console.log('Delete confirmed');

      EntityService.delete(
        (response) => this.handleDeleteError(response),
        (response) => this.handleDeleteSuccess(response),
        deleteData);
    } else {
      console.log('Delete canceled');
    }
  }

  render() {
    const entity = this.state.entity;
    return (
      <div className="page-cont">
      {this.user && <EditorPane username={this.user['cognito:username']}
                                status={this.state.status}
                                entity={this.state.entity}
                                prompt={this.state.editorPrompt}
                                updateCallback={this.handleUpdate}
                                deleteCallback={this.handleDelete}
                                statusCallback={this.handleStatusUpdate}/>}
      <section className={"lead entity-lead" + (this.state.status === 'DELETED' ? ' entity-deleted' : '')}>
        <HeaderWidget status={this.state.status}
                      entity={this.state.entity}
                      callback={this.handleUpdate}/>
        <div className="production-img-summary">
          <PosterImage />
          <div className="production-summary-box">
            <SingleTextArea status={this.state.status}
                            scope="summary"
                            text={this.state.status !== 'DELETED' ? entity.data.overview.summary : 'This production has been deleted. It will no longer be found by other users nor yourself once you leave this page.'}
                            lines="9"
                            callback={this.handleUpdate} />
            <LocationWidget status={this.state.status}
                            location={entity.data.overview.location}
                            dates={entity.data.overview.dates}
                            callback={this.handleUpdate} />
          </div>
        </div>

      </section>
      <main className="cont">
        {this.listCreditBoxes(Object.keys(this.state.entity.data.credits))}
        <CreditBoxAdder status={this.state.status} currentBoxes={Object.keys(this.state.entity.data.credits)} callback={this.handleAddCreditSection} />
        <EntityAnalytics user={this.user} id={this.state.entity.id} />
      </main>

      </div>
    )
  }
}

class EditorPane extends Component {
  constructor(props) {
    super(props);

    this.username = props.username;
    this.onDelete = props.deleteCallback;
    this.onUpdate = props.updateCallback;
    this.onStatusChange = props.statusCallback;

    this.state = {
      status: props.status,
      entity: props.entity,
      prompt: props.prompt,
      showRaw: false,
      prevProps: props
    };

    this.handleShowRawClick = this.handleShowRawClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return JSON.stringify(prevState.prevProps) === JSON.stringify(nextProps) ?
      null : {
        status: nextProps.status,
        entity: nextProps.entity,
        prompt: nextProps.prompt,
        prevProps: nextProps
      };
  }

  shouldShowDelete() {
    if (this.state.status !== 'DISPLAY') return false;
    const credits = [].concat.apply([], Object.values(this.state.entity.data.credits));
    return credits.length === 0;
  }

  handleShowRawClick(e) {
    e.preventDefault();

    this.setState((prevState, props) => {
      return { showRaw: !prevState.showRaw };
    });
  }

  handleEditClick(e) {
    e.preventDefault();

    console.log('HandleEditClick');
    this.setState({
      status: 'EDIT_FOCUS'
    });
  }

  handleDeleteClick(e) {
    e.preventDefault();

    console.log('HandleDeleteClick');
  }

  handleDoneClick(e) {
    e.preventDefault();

    console.log('HandleDoneClick');
    this.setState({
      status: 'EDIT_PENDING'
    }, () => this.onUpdate({
      ref: { id: this.state.id, name: this.state.name },
      credit: this.state.credit
    }));
  }

  render() {
    if (this.state.status === 'DELETED') {
      return (
        <div className="edit entity-editor">
        <div className="entity-editor-title">
          <h4>Editor ({this.username})</h4>
          <div className="entity-editor-deleted">
            Production deleted.
          </div>
        </div>
      </div>
      )
    }
    return (
      <div className="edit entity-editor">
        <div className="entity-editor-title">
          <h4>Editor ({this.username})</h4>
          <div className="entity-editor-toggle">
            {this.shouldShowDelete() && <button onClick={this.onDelete}>delete</button>}
            <button className="buttonnext" onClick={this.onStatusChange}>{this.state.status === 'DISPLAY' ? 'edit' : 'exit edit mode'}</button>
          </div>
        </div>
        {this.state.status === 'EDIT' &&
          <div className="entity-editor-content">
            <h5>Notes:</h5>
            <SingleTextArea status={this.state.status} scope="notes" text={this.state.entity.metadata.notes} callback={this.onUpdate} />
            {this.state.prompt && <h5>Messages:</h5>}
            <p>{this.state.prompt}</p>
            <RawJson hidden={!this.state.showRaw} data={this.state.entity} />
            <button onClick={this.handleShowRawClick}>{this.state.showRaw ? 'hide' : 'show' } raw data</button>
          </div>
        }
      </div>
    );
  }
}

export default ProductionEntity;