import React, { Component } from 'react';
import Nav from './Nav';
import Spinner from './Spinner';
import ProductionEntity from './ProductionEntity';
import NonProductionEntity from './NonProductionEntity';
import EntityService from './services/EntityService';
import AnalyticsService from './services/AnalyticsService';
import './Page.css';
import './Entity.css';


function ErrorEntity(props) {
  return (
    <div className="page-cont">
      <main className="cont entity-error">
      <h1>Error:</h1>
      <p>{props.message}</p>
      </main>
    </div>
  );
}

// props = {
//   id: "this id of the entity for display",
//   user: "the user details"
// }
class EntityCont extends Component {
  constructor(props) {
    super(props);

    this.user = props.user;
    this.state = {
      id: props.id.toLowerCase(),
      status: 'PENDING'
    };

    this.handleError = this.handleError.bind(this);
    this.handleSuccss = this.handleSuccess.bind(this);

    EntityService.get(this.handleError.bind(this), this.handleSuccess.bind(this), { id: this.state.id });
  }

  handleError(data) {
    console.error('HandleError', data);
    this.setState({
      message: data,
      status: 'ERROR'
    });
  }

  handleSuccess(data) {
    console.log('HandleSuccess', data);
    this.setState({
      type: data.entity.type,
      entity: data.entity,
      status: 'DONE'
    }, () => {
      AnalyticsService.hit({ id: data.entity.id, name: data.entity.name, user: this.user ? this.user['cognito:username'] : 'unrec' })
    });
  }

  render() {
    switch(this.state.status) {
      case 'PENDING':
      return (
        <div className="cont">
          <Spinner />
        </div>
        )
      case 'ERROR':
      return <ErrorEntity message={this.state.message} />
      case 'DONE':
        document.title = this.state.entity.name + ' - Performing.World';
        switch(this.state.entity.type) {
          case 'production': return <ProductionEntity entity={this.state.entity} user={this.user}/>
          case     'person': return <NonProductionEntity entity={this.state.entity} user={this.user}/>
          case        'org': return <NonProductionEntity entity={this.state.entity} user={this.user}/>
          case       'user': return <NonProductionEntity entity={this.state.entity} user={this.user}/>
          default          : return <ErrorEntity message="Sorry" />
        }
      default: return <ErrorEntity message="Sorry" />
    }
  }
}

// props = {
//   user: "the user details"
// }
class Entity extends Component {
  render() {
    return (
      <div className="page-navi">
        <Nav user={this.props.user}/>
        <EntityCont id={this.props.id} user={this.props.user}/>
      </div>
    );
  }
}

export default Entity;
