import Amplify, { API } from 'aws-amplify';
import Constants from '../config/Constants';

Amplify.configure(Constants.amplify);

const get = (errorCb, successCb, data) => {
    console.log("Get Analytics", data);

    API.get('DataService', '/data/v1/analytics/' + data.id)
    .then(response => {
      successCb(response);
    })
    .catch((err) => {
      errorCb(err);
    });
};

const hit = (data) => {
    console.log("Record Hit", data);

    API.post('DataService', '/data/v1/analytics/' + data.id, {
        body: data
    })
    .catch((err) => {
      console.warn('analytics hit failure', err);
    });
};

export default {
    get: get,
    hit: hit
}